// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyA2e7KimzDLS1HwQK_Y0wqDKXL6xsmU_FA",
  authDomain: "yvomrknew1.firebaseapp.com",
  databaseURL: "https://yvomrknew1-default-rtdb.firebaseio.com",
  projectId: "yvomrknew1",
  storageBucket: "yvomrknew1.appspot.com",
  messagingSenderId: "1955392165",
  appId: "1:1955392165:web:c0ba660c7a8b778d5dbb4c",
  measurementId: "G-87XCBWRR09"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };